import { useState } from "react";
import axios from "axios";
import { LinearProgress, Box, Button, Typography } from "@mui/material";

const UploadFiles = () => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
 

  const selectFile = (event) => {
    const file = event.target.files[0]; // Get the selected file
    const allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i; // Regex to check allowed file extensions
  
    if (file && !allowedExtensions.test(file.name)) {
      alert("Only CSV and Excel files are allowed.");
      event.target.value = ""; // Reset the file input
    } else {
      setSelectedFiles(event.target.files);
    }
  };
  

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    const formData = new FormData();
    formData.append("csvFile", currentFile);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}csv/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const calculatedProgress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
          setProgress(calculatedProgress);
        },
      })
      .then((response) => {
        setMessage(response.data.message);
        setIsError(false);
      })
      .catch((error) => {
        console.error("Upload Error:", error);
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
        setIsError(true);
      });
      

    setSelectedFiles(undefined);
  };


  return (
    <div className="mg20">
      {currentFile && (
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
          <LinearProgress
              variant="determinate"
              value={progress}
              style={{
                height: 15,
                borderRadius: 5,
                backgroundColor: "#EEEEEE",
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
          </Box>
        </Box>
      )}

      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: "none" }}
          type="file"
          accept=".csv" 
          onChange={selectFile}
        />
        <Button className="btn-choose" variant="outlined" component="span">
          Choose Files
        </Button>
      </label>
      <div className="file-name">
        {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
      </div>
      <Button
        className="btn-upload"
        color="primary"
        variant="contained"
        component="span"
        disabled={!selectedFiles}
        onClick={upload}
      >
        Upload
      </Button>

      <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
        {message}
      </Typography>
    </div>
  );
};

export default UploadFiles;
