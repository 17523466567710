import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useSelector } from "react-redux";
import Routes from "routes";
import Login from "../src/views/login/index";
import themes from "themes";
import NavigationScroll from "layout/NavigationScroll";
import { useUser } from "contexts/UserContext";

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { user } = useUser();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>{user ? <Routes /> : <Login />}</NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
