import bknd from "../assets/images/bknd.png";
const Logo = () => (
    <img
        src={bknd}
        alt="BKND"
        width="140"
    />
);

export default Logo;
