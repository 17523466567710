import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// sample page routing
const Login = Loadable(lazy(() => import("views/login")));

const LoginRoute = {
  path: "/login",
  element: <Login />,
};

export default LoginRoute;
