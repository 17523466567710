import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LostRoutes from './lost';
import LoginRoute from './LoginRoute';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LostRoutes, LoginRoute]);
}
