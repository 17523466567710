export const getUserById = async (userId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}users/${userId}`);
    if (response.ok) {
      const userFromDb = await response.json();
      return userFromDb;
    } else {
      throw new Error('Failed to fetch user data');
    }
  } catch (error) {
    console.error('Error fetching user by ID:', error);
    throw error;
  }
};
