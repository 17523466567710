import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UploadCsv from 'views/upload';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// sample page routing
const Offers = Loadable(lazy(() => import('views/sessions')));
const Users = Loadable(lazy(() => import('views/users')));
const Csvs = Loadable(lazy(() => import('views/csvs')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'Users',
            element: <Users />
        },
        {
            path: 'Sessions',
            element: <Offers />
        },
        {
            path: 'CSVS',
            element: <Csvs />
        },
        {
            path: 'Upload-CSV',
            element: <UploadCsv/>
        },
    ]
};

export default MainRoutes;
