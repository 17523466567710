// material-ui
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
// project imports
import MainCard from "ui-component/cards/MainCard";
import UploadFiles from "ui-component/upload/UploadFiles";
import UploadSessionFiles from "ui-component/upload/UploadSessionFiles";

// ==============================|| SAMPLE PAGE ||============================== //

const UploadCsv = () => (
  <>
    <MainCard title="Upload CSV">
      <Typography variant="body2">Upload your CSV files here.</Typography>
      <UploadFiles />
    </MainCard>

    <Box sx={{ height: 16 }} /><Box sx={{ height: 16 }} />

    <MainCard title="Upload Session CSV" >
      <Typography variant="body2">Upload your Sessions CSV files here.</Typography>
      <UploadSessionFiles />
    </MainCard>
  </>
);

export default UploadCsv;
