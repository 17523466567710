// assets
import { IconDashboard, IconUsers, IconUpload, IconTable } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUsers,IconUpload, IconTable};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Admin Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'Sessions',
            title: 'Sessions',
            type: 'item',
            url: '/sessions',
            icon: icons.IconTable,
            breadcrumbs: false
        },
        {
            id: 'Csvs',
            title: 'Csvs',
            type: 'item',
            url: '/Csvs',
            icon: icons.IconTable,
            breadcrumbs: false
        },
        {
            id: 'Upload_CSV',
            title: 'Upload CSV',
            type: 'item',
            url: '/upload-csv',
            icon: icons.IconUpload,
            breadcrumbs: false
        },
        {
            id: 'Users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
