import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import { getUserById } from './api'; 

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('BKNDuser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const verifyToken = useCallback((token) => {
    try {
      const decodedToken = jwt_decode(token);
      
      // Call the function to fetch user data by userId
      getUserById(decodedToken.id)
        .then((userFromDb) => {
          if (userFromDb && userFromDb.role === decodedToken.role) {
            // Handle user data verification
          } else {
            // Handle user data mismatch
          }
        })
        .catch((error) => {
          // Handle database error
          console.error('Error fetching user:', error);
        });
    } catch (error) {
      // Handle token verification error
      console.error('Token verification error:', error);
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('BKNDuser', JSON.stringify(user));
      verifyToken(user.token);
    } else {
      localStorage.removeItem('BKNDuser');
    }
  }, [user, verifyToken]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
