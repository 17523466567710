import { lazy } from 'react';
// project imports
import Loadable from 'ui-component/Loadable';
// 404 routing
const NotFound = Loadable(lazy(() => import('views/lost')));


// ==============================|| MAIN ROUTING ||============================== //

const LostRoutes = {
    path: '*',
    element: <NotFound />,

};

export default LostRoutes;
